<template>
  <div v-html="getPortalBlock" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "portal-block",
  props: {
    portalBlock: { default: 1 },
  },
  computed: {
    ...mapGetters(["getOrganization"]),
    getPortalBlock() {
      switch (this.portalBlock) {
        case 1:
          return this.getOrganization?.data?.portalBlock1 || "";
        case 2:
          return this.getOrganization?.data?.portalBlock2 || "";
        case 3:
          return this.getOrganization?.data?.portalBlock3 || "";
        case 4:
          return this.getOrganization?.data?.portalBlock4 || "";
        case 5:
          return this.getOrganization?.data?.portalBlock5 || "";
        default:
          return "";
      }
    },
  },
};
</script>
